@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

$px-theme: #007b78 !default;
$px-dark: #1c1d24 !default;

$px-white: #fff !default;
$px-black: #131416 !default;

$px-gray: #fff5ec !default;

$px-body: #5e6472 !default;
$px-font-size: 16px !default;
$px-line-height: 1.7 !default;
$px-font: "Nunito", monospac !default;
$px-font-alt: "Nunito", serif !default;

$px-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px !default;
$px-shadow-sm: 0 0 30px rgba(0, 0, 0, 0.1%) !default;

// Breakpoints
$px-media-xsm: 567px !default;
$px-media-sm: 767px !default;
$px-media-md: 991px !default;
$px-media-lg: 1200px !default;
